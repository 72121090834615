import React, { useContext, useEffect, useState } from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Dialog from '@material-ui/core/Dialog';
import Box from '@material-ui/core/Box';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { WidgetBuilderAffiliateContext } from './WidgetBuilderAffiliateProvider';
import { useDataProvider } from 'ra-core';
import CircularProgress from '@material-ui/core/CircularProgress';

export default function AdminSelectAffiliateDialog() {
    const [open, setOpen] = React.useState(true);

    const { currentAffiliate, setAffiliate, update } = useContext(WidgetBuilderAffiliateContext);

    const handleClose = () => {
        update('adminSelectAffiliateModalOpen', false);
        setOpen(false);
    };

    const handleChange = (e) => {
        update('adminSelectAffiliateModalOpen', false);
        setAffiliate(e.target.value);
    };

    const dataProvider = useDataProvider();
    const [affiliates, setAffiliates] = useState(null);

    useEffect(async () => {
        if (!affiliates) {
            const { data } = await dataProvider.getList('affiliates', {});
            setAffiliates(data);
        }
    }, [affiliates]);

    return (
        <Dialog fullWidth maxWidth="sm" open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Select Affiliate</DialogTitle>
            <DialogContent>
                <DialogContentText>Select an affiliate to preview how the widgets will render.</DialogContentText>
                <Box mt={4} mb={4}>
                    {affiliates ? (
                        <Select
                            style={{ height: 36 }}
                            color="secondary"
                            variant="outlined"
                            fullWidth
                            value={currentAffiliate}
                            onChange={handleChange}>
                            {affiliates.map((affiliate) => {
                                return (
                                    <MenuItem key={affiliate.company_name} value={affiliate}>
                                        {affiliate.company_name}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    ) : (
                        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                            <CircularProgress color="secondary" />
                        </Box>
                    )}
                </Box>
            </DialogContent>
        </Dialog>
    );
}
