import * as React from 'react';
// import { Layout } from '@react-admin/ra-enterprise';
import CustomAppBar from './AppBar';
import CustomNotification from './Notification';
import { Layout } from 'react-admin';
import { CustomMenu } from './MenuSidebar';
import { AppLocationContext } from '@react-admin/ra-navigation';
import UserProvider from './UserProvider';
import WidgetBuilderAffiliateProvider from '../WidgetBuilder/WidgetBuilderAffiliateProvider';

const CustomLayout = (props) => {
    return (
        <UserProvider>
            <WidgetBuilderAffiliateProvider>
                <AppLocationContext>
                    <Layout {...props} menu={CustomMenu} notification={CustomNotification} appBar={CustomAppBar} />
                </AppLocationContext>
            </WidgetBuilderAffiliateProvider>
        </UserProvider>
    );
};

export default CustomLayout;
