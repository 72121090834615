import React, { useContext, useState } from 'react';
import { useLogout, usePermissions } from 'ra-core';
import { AppBar, Toolbar } from '@material-ui/core';
import logo from '../../img/DSA-Logo.svg';
import { Box, Button, Menu, MenuItem } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { UserProviderContext } from './UserProvider';

const CustomAppBar = (props) => {
    const { permissions, loading } = usePermissions();
    const { currentUser } = useContext(UserProviderContext);

    const [anchorEl, setAnchorEl] = useState(null);

    const handleOpenMenu = (event) => setAnchorEl(event.currentTarget);

    const handleCloseMenu = () => setAnchorEl(null);

    const logout = useLogout();

    return (
        <AppBar elevation={5} {...props}>
            <Toolbar disableGutters variant="dense">
                <Box
                    display={'flex'}
                    alignItems={'center'}
                    justifyContent={'space-between'}
                    width={'100%'}
                    mr={2}
                    ml={2}>
                    <img src={logo} alt="DSA Logo" />
                    <div style={{ marginRight: 'auto' }}></div>
                    <h4 style={{ margin: 0 }}>{currentUser ? currentUser.email : ''}</h4>
                    &nbsp; {!loading && permissions[0] === 'admin' && `(${permissions[0]})`} &nbsp;
                    <Button size="small" onClick={handleOpenMenu}>
                        <MenuIcon />
                    </Button>
                    <Menu
                        id="simple-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleCloseMenu}>
                        {/* {!loading && permissions[0] === 'admin' && (
                            <MenuItem onClick={handleCloseMenu} component="a" href="/#/profile">
                                Profile
                            </MenuItem>
                        )} */}
                        {currentUser && <MenuItem disabled>{currentUser.name}</MenuItem>}
                        <MenuItem onClick={logout}>Logout</MenuItem>
                    </Menu>
                </Box>
            </Toolbar>
        </AppBar>
    );
};

export default CustomAppBar;
