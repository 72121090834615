import React, { useContext } from 'react';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import WidgetPreview from './WidgetPreview';
import WidgetSettings from './WidgetSettings';
import WidgetTypeSelect from './WidgetTypeSelect';
import { WidgetBuilderContext, WIDGET_TYPE } from './WidgetBuilderProvider';
import WidgetGamesList from './WidgetGamesList';
import WidgetTeamsList from './WidgetTeamsList';

export default function WidgetBuilderContent() {
    const { widgetType } = useContext(WidgetBuilderContext);
    return (
        <Box px={2} display="flex">
            <Box flex={1}>
                <WidgetTypeSelect />
                <Box mt={2} mb={2}>
                    {widgetType === WIDGET_TYPE.SINGLE_MATCHUP && <WidgetGamesList />}
                    {widgetType === WIDGET_TYPE.SINGLE_TEAM && <WidgetTeamsList />}
                    {widgetType === WIDGET_TYPE.UPCOMING_MATCHUPS && (
                        <Typography variant="subtitle1">Automatically display the next 5 upcoming games</Typography>
                    )}
                </Box>
            </Box>
            <Box flex={4} ml={2}>
                <Box mb={2}>
                    <Card variant="outlined">
                        <Box p={2}>
                            <WidgetSettings />
                            <WidgetPreview />
                        </Box>
                    </Card>
                </Box>
            </Box>
        </Box>
    );
}
