import * as React from 'react';
import { useState } from 'react';
import { useNotify } from 'react-admin';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import { withStyles } from '@material-ui/core/styles';
import CustomNotification from '../components/General/Notification';
import Auth from '@aws-amplify/auth';
import logo from '../img/DSA-Logo.svg';

const PasswordReset = ({ classes }) => {
    const [email, setEmail] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmationCode, setConfirmationCode] = useState('');
    const [submitted, setSubmitted] = useState(false);
    const notify = useNotify();

    const submit = (e) => {
        e.preventDefault();
        notify(`Confirmation code sent to ${email}`);
        Auth.forgotPassword(email)
            .then((data) => setSubmitted(true))
            .catch((err) => notify(err.message));
    };

    const submitNewPassword = (e) => {
        e.preventDefault();
        Auth.forgotPasswordSubmit(email, confirmationCode, newPassword)
            .then((data) => {
                // redirect to login?
                window.location = '/#/login';
                notify('Password Reset');
            })
            .catch((err) => console.log(err));
    };

    return (
        <form onSubmit={submitted ? submitNewPassword : submit} noValidate>
            <div className={classes.main}>
                <Card className={classes.card}>
                    <Box display={'flex'} pt={3} justifyContent="center">
                        <img src={logo} alt="DSA Logo" />
                    </Box>
                    <div className={classes.form}>
                        <div className={classes.input} style={{ display: submitted ? 'none' : 'block' }}>
                            <TextField
                                fullWidth
                                variant={'outlined'}
                                color="secondary"
                                autoFocus
                                name="email"
                                label={'Email'}
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </div>
                        {submitted && (
                            <>
                                <div className={classes.input}>
                                    <TextField
                                        fullWidth
                                        variant={'outlined'}
                                        color="secondary"
                                        autoFocus
                                        name="password"
                                        label={'New Password'}
                                        value={newPassword}
                                        onChange={(e) => setNewPassword(e.target.value)}
                                    />
                                </div>
                                <div className={classes.input}>
                                    <TextField
                                        fullWidth
                                        variant={'outlined'}
                                        color="secondary"
                                        autoFocus
                                        name="password"
                                        label={'Confirmation Code'}
                                        value={confirmationCode}
                                        onChange={(e) => setConfirmationCode(e.target.value)}
                                    />
                                </div>
                            </>
                        )}
                    </div>
                    <div className={classes.actions}>
                        <Button
                            className={classes.submit}
                            variant="contained"
                            type="submit"
                            color="primary"
                            // disabled={loading}
                            fullWidth>
                            {/* {loading && <CircularProgress size={25} thickness={2} />} */}
                            Reset Password
                        </Button>
                    </div>
                </Card>
                <p>
                    Already have an account? <a href="/#/login">Sign In</a>
                </p>
                <CustomNotification />
            </div>
        </form>
    );
};

export const loginStyles = (theme) => ({
    main: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        alignItems: 'center',
        justifyContent: 'flex-start',
        backgroundColor: theme.palette.background.default,
    },
    card: {
        minWidth: 400,
        marginTop: '6em',
    },
    input: {
        marginTop: '1.5em',
        padding: '0 1em',
    },
    field: {
        width: '100%',
    },
    actions: {
        padding: '0 1em 1em 1em',
    },
    avatar: {
        display: 'flex',
        justifyContent: 'center',
        margin: '1.5em',
    },
    submit: {
        margin: theme.spacing(3, 0, 1),
    },
});

export default withStyles(loginStyles)(PasswordReset);
