import React, { useContext, useEffect } from 'react';
import { WidgetBuilderContext } from './WidgetBuilderProvider';
import LeagueSelect from './LeagueSelect';
import WidgetLoaderSkeleton from './WidgetLoaderSkeleton';
import { Fade, Box, Card } from '@material-ui/core';
import { SEASON_STATUS_ENDPOINT } from '../../lib/endpoints';
import EmbedModal from './EmbedModal';
import { WidgetBuilderAffiliateContext } from './WidgetBuilderAffiliateProvider';
import { usePermissions } from 'ra-core';
import AdminSelectAffiliateDialog from './AdminSelectAffiliateModal';
import AffiliateBar from '../Affiliates/AffiliateBar';
import { UserProviderContext } from '../General/UserProvider';

export default function WidgetBuilder() {
    const { update, embedModalOpen } = useContext(WidgetBuilderContext);
    const { currentAffiliate, setAffiliate, toggleAdminAffiliateSelectModal, adminSelectAffiliateModalOpen } =
        useContext(WidgetBuilderAffiliateContext);
    const { permissions } = usePermissions();
    const { currentUser } = useContext(UserProviderContext);

    useEffect(async () => {
        if (permissions) {
            if (currentAffiliate) {
                const data = await fetch(SEASON_STATUS_ENDPOINT);
                const seasons = await data.json();
                update('leagues', seasons);
            } else {
                if (permissions[0] === 'user') {
                    if (currentUser) {
                        setAffiliate(currentUser.affiliate);
                    }
                }
            }
        }
    }, [currentAffiliate, permissions, currentUser]);

    return (
        <Box mt={2}>
            {currentAffiliate ? (
                <Fade timeout={850} in={true}>
                    <div>
                        <AffiliateBar affiliate={currentAffiliate} />
                        <Card elevation={2}>
                            {embedModalOpen && <EmbedModal />}
                            <LeagueSelect />
                        </Card>
                    </div>
                </Fade>
            ) : (
                <WidgetLoaderSkeleton />
            )}
            {!currentAffiliate && adminSelectAffiliateModalOpen && permissions && permissions[0] === 'admin' && (
                <AdminSelectAffiliateDialog />
            )}
        </Box>
    );
}
