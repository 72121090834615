export const getParamFromUrl = (queryString, key) => {
    const urlParams = new URLSearchParams(decodeURIComponent(queryString));
    const entity = JSON.parse(urlParams.get(key));
    return entity;
};

export const extractIdentifier = (identifier) => identifier.split(':')[1];

export const KEVEL_AD_SIZE_MAP = {
    ['300x250']: 5,
    ['300x600']: 43,
    ['728x90']: 4,
    ['300x50']: 22,
    ['320x50']: 23,
    ['600x400']: 191,
    ['300x1050']: 934,
};

export const hexIsTooLight = (hexString) => {
    const hex = hexString.replace('#', '');
    const CR = parseInt(hex.substr(0, 2), 16);
    const CG = parseInt(hex.substr(2, 2), 16);
    const CB = parseInt(hex.substr(4, 2), 16);
    const brightness = (CR * 299 + CG * 587 + CB * 114) / 1000;
    return brightness > 155;
};

export const alphabetical = (a, b) => {
    var nameA = a.Name.toUpperCase();
    var nameB = b.Name.toUpperCase();
    if (nameA < nameB) {
        return -1;
    }
    if (nameA > nameB) {
        return 1;
    }
    return 0;
};
