const FEEDS_DOMAIN = 'https://api.sbdfuel.com';
const isDev = process.env.NODE_ENV === 'development';
export const PROD_DOMAIN = 'https://staging.cdn.sbdfuel.com';
const WIDGET_DOMAIN = isDev ? 'http://192.168.1.72:8080' : `${PROD_DOMAIN}/index.html`;
export const API_URL = isDev
    ? 'http://localhost:3100/dev'
    : 'https://ulz8yhxaq8.execute-api.us-east-1.amazonaws.com/dev';
export const KEVEL_LAMBDA__PROXY = `${API_URL}/kevel/relay`;
export const SEASON_STATUS_ENDPOINT = FEEDS_DOMAIN + '/seasons/status';
export const KEVEL_API = 'https://api.kevel.co/v1';

export const UPCOMING_GAMES_ENDPOINT = (league, oddsType, oddsFormat) => {
    // No Books passed. Used for UI purposes and not for odds
    return `${FEEDS_DOMAIN}/odds/${league.toLowerCase()}/bet/${oddsType}/widget?format=${oddsFormat}&limit=20`;
};

export const LEAGUE_TEAMS_ENDPOINT = (league) => {
    return `${FEEDS_DOMAIN}/teams/${league.toLowerCase()}`;
};

export const WIDGET_DEMO_ENDPOINT = (payload) => {
    return `${WIDGET_DOMAIN}?widget=${encodeURIComponent(JSON.stringify(payload))}`;
};

export const ODDS_SOURCES_ENDPOINT = `${FEEDS_DOMAIN}/odds/category/sources`;
