import React, { Fragment, useContext, useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import CardActionArea from '@material-ui/core/CardActionArea';
import CircularProgress from '@material-ui/core/CircularProgress';
import { WidgetBuilderContext } from './WidgetBuilderProvider';
import { makeStyles } from '@material-ui/core/styles';
import { FixedSizeList } from 'react-window';
import TextField from '@material-ui/core/TextField';

export default function WidgetTeamsList() {
    const { selectedLeagueTeams, selectedLeagueSelectedTeam, update, getSelectedLeague, loading } =
        useContext(WidgetBuilderContext);

    const handleClickGame = (team) => update('selectedLeagueSelectedTeam', team);

    const [sortedTeams, setSortedTeams] = useState(selectedLeagueTeams);

    const Row = ({ index, style }) => {
        const team = sortedTeams[index];
        const selected = selectedLeagueSelectedTeam && selectedLeagueSelectedTeam.code === team.code;
        return (
            <div style={style}>
                <Card
                    style={{
                        width: '100%',
                        borderBottom: '1px solid #efefef',
                        backgroundColor: selected ? '#efefef' : 'initial',
                    }}
                    elevation={0}>
                    <CardActionArea onClick={() => handleClickGame(team)}>
                        <Box display={'flex'} flexDirection={'column'} p={2}>
                            <h3 style={{ margin: '0 0 5px' }}>{team.name}</h3>
                            <span>({team.nick})</span>
                        </Box>
                    </CardActionArea>
                </Card>
            </div>
        );
    };

    useEffect(() => {
        if (selectedLeagueTeams.length > 0) {
            setSortedTeams(selectedLeagueTeams);
        }
    }, [selectedLeagueTeams]);

    return (
        <>
            <h3 style={{ margin: '30px 0 10px' }}>All {getSelectedLeague().toUpperCase()} Teams</h3>
            <Card variant="outlined">
                <Box p={2}>
                    <Box p={2} pt={0}>
                        <TextField
                            onChange={({ target }) => {
                                console.log(target.value);
                                const val = target.value.toLowerCase();
                                setSortedTeams(
                                    selectedLeagueTeams.filter((team) => {
                                        if (
                                            team.name.toLowerCase().includes(val) ||
                                            team.nick.toLowerCase().includes(val)
                                        ) {
                                            return team;
                                        }
                                    })
                                );
                            }}
                            fullWidth
                            color="secondary"
                            label="Search..."
                        />
                    </Box>

                    {!loading && sortedTeams.length > 0 && (
                        <FixedSizeList height={500} itemCount={sortedTeams.length} itemSize={80} width={'100%'}>
                            {Row}
                        </FixedSizeList>
                    )}

                    {loading && (
                        <Box p={5} display="flex" alignItems="center" justifyContent="center">
                            <CircularProgress size={25} color="secondary" />
                        </Box>
                    )}
                </Box>
            </Card>
        </>
    );
}
