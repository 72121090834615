import * as React from 'react';
import { useState } from 'react';
import { useNotify } from 'react-admin';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import { withStyles } from '@material-ui/core/styles';
import CustomNotification from '../components/General/Notification';
import Auth from '@aws-amplify/auth';
import { loginStyles } from './PasswordReset';
import logo from '../img/DSA-Logo.svg';

const ConfirmNewUser = ({ classes }) => {
    const [email, setEmail] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [tempPassword, setTempPassword] = useState('');
    const notify = useNotify();

    const handleSubmit = (e) => {
        e.preventDefault();

        Auth.signIn(email, tempPassword)
            .then(async (user) => {
                await Auth.completeNewPassword(user, newPassword);
                notify(`Password Updated: ${email}`);
                window.location = '/';
            })
            .catch((err) => {
                notify(err.message);
            });
    };

    return (
        <form onSubmit={handleSubmit} noValidate>
            <div className={classes.main}>
                <Card className={classes.card}>
                    <Box display={'flex'} pt={3} justifyContent="center">
                        <img src={logo} alt="DSA Logo" />
                    </Box>
                    <div className={classes.form}>
                        <div className={classes.input}>
                            <TextField
                                fullWidth
                                variant={'outlined'}
                                color="secondary"
                                autoFocus
                                name="email"
                                label={'Email'}
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </div>
                        <>
                            <div className={classes.input}>
                                <TextField
                                    fullWidth
                                    variant={'outlined'}
                                    color="secondary"
                                    name="temp-password"
                                    label={'Temporary Password'}
                                    value={tempPassword}
                                    onChange={(e) => setTempPassword(e.target.value)}
                                />
                            </div>
                            <div className={classes.input}>
                                <TextField
                                    type="password"
                                    fullWidth
                                    variant={'outlined'}
                                    color="secondary"
                                    name="new-password"
                                    label={'New Password'}
                                    value={newPassword}
                                    onChange={(e) => setNewPassword(e.target.value)}
                                />
                            </div>
                        </>
                    </div>
                    <div className={classes.actions}>
                        <Button
                            className={classes.submit}
                            variant="contained"
                            type="submit"
                            color="primary"
                            // disabled={loading}
                            fullWidth>
                            {/* {loading && <CircularProgress size={25} thickness={2} />} */}
                            Set New Password
                        </Button>
                    </div>
                </Card>
                <p>
                    Already have an account? <a href="/#/login">Sign In</a>
                </p>
                <CustomNotification />
            </div>
        </form>
    );
};

export default withStyles(loginStyles)(ConfirmNewUser);
