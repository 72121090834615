import React from 'react';
import Box from '@material-ui/core/Box';

export default function WidgetPlaceholder({ width, height }) {
    return (
        <Box
            mb={2}
            style={{ width: width + 'px', height: height + 'px', borderRadius: 2, backgroundColor: '#efefef' }}></Box>
    );
}
