import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, DialogContent } from '@material-ui/core';
import MuiTextField from '@material-ui/core/TextField';
import { Form, Field } from 'react-final-form';
import Dialog from '@material-ui/core/Dialog';
import { useCreate, useNotify } from 'ra-core';
import arrayMutators from 'final-form-arrays';
import { withStyles } from '@material-ui/core';
import Auth from '@aws-amplify/auth';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles({});
const TextInput = withStyles({
    root: {
        margin: '16px 0px',
    },
})(MuiTextField);

const defaultSubscription = {
    submitting: true,
    pristine: true,
    valid: true,
    invalid: true,
};

function UserCreateDialog({ open, handleClose, version, affiliate, onChange }) {
    const classes = useStyles();
    const [create, { loading }] = useCreate('affiliates/users');
    const notify = useNotify();

    const signUpAmplify = async (email) => {
        const tempPassword = Math.random().toString(36).slice(2) + '_DSA';
        try {
            const { user, userSub } = await Auth.signUp({
                username: email,
                password: tempPassword,
                attributes: {
                    email,
                },
            });
            return userSub;
        } catch (error) {
            notify('Error signing up');
            console.log('error signing up:', error);
        }
    };

    const onSubmit = async (values) => {
        // Create user in AWS.
        const cognitoUserID = await signUpAmplify(values.email);

        if (cognitoUserID) {
            // Create user in DB.
            const payload = {
                ...values,
                affiliateID: affiliate.id,
                cognitoID: cognitoUserID,
            };
            create(
                { payload: { data: payload } },
                {
                    onSuccess: ({ data }) => {
                        onChange();
                        handleClose(false);
                    },
                    onFailure: ({ error }) => {
                        console.log(error);
                        notify(`Error creating user:${error}`);
                    },
                }
            );
        } else {
            notify('Error signing up');
        }
    };

    const validateForm = (values) => {
        const errors = {};
        if (!values.name) {
            errors.name = 'Required';
        }
        if (!values.email) {
            errors.email = 'Required';
        }
        return errors;
    };

    return (
        <Dialog
            maxWidth="xs"
            fullWidth
            onClose={() => handleClose(false)}
            aria-labelledby="add-user-dialog-title"
            open={open}>
            <DialogContent>
                <Form
                    onSubmit={onSubmit}
                    mutators={{ ...arrayMutators }} // necessary for ArrayInput
                    subscription={defaultSubscription} // do not redraw entire form each time one field changes
                    key={version}
                    keepDirtyOnReinitialize
                    validate={validateForm}
                    render={({ handleSubmit, form, submitting, pristine, values, invalid }) => (
                        <form onSubmit={handleSubmit}>
                            <Field name="name">
                                {({ input }) => (
                                    <div>
                                        <TextInput
                                            fullWidth
                                            required
                                            label={'Name'}
                                            variant="outlined"
                                            name={input.name}
                                            value={input.value}
                                            onChange={input.onChange}
                                        />
                                    </div>
                                )}
                            </Field>
                            <Field name="email" label="Email">
                                {({ input }) => (
                                    <div>
                                        <TextInput
                                            fullWidth
                                            required
                                            label={'Email Address'}
                                            variant="outlined"
                                            name={input.name}
                                            value={input.value}
                                            onChange={input.onChange}
                                        />
                                    </div>
                                )}
                            </Field>
                            <Button
                                variant="text"
                                color="secondary"
                                type="submit"
                                style={{ marginBottom: 10, marginTop: 20 }}
                                disabled={submitting || pristine || invalid || loading}>
                                {loading ? <CircularProgress color="secondary" size={20} /> : 'Create User'}
                            </Button>
                        </form>
                    )}
                />
            </DialogContent>
        </Dialog>
    );
}

export default UserCreateDialog;
