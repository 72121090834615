import React from 'react';
import WidgetBuilderProvider from '../components/WidgetBuilder/WidgetBuilderProvider';
import WidgetBuilder from '../components/WidgetBuilder/WidgetBuilder';
import { useAuthState } from 'ra-core';
import { Redirect } from 'react-router';

const Dashboard = () => {
    document.title = 'DSA - Widget Builder';
    const { authenticated } = useAuthState();

    if (!authenticated) {
        return <Redirect to="/login" />;
    }

    return (
        <WidgetBuilderProvider>
            <WidgetBuilder />
        </WidgetBuilderProvider>
    );
};

export default Dashboard;
