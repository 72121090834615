import React, { useContext } from 'react';
import Box from '@material-ui/core/Box';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { WidgetBuilderContext, WIDGET_TYPE } from './WidgetBuilderProvider';

export default function WidgetTypeSelect() {
    const { widgetType, updateWidgetType, getSelectedLeague } = useContext(WidgetBuilderContext);
    const handleChange = (e) => updateWidgetType(e.target.value);
    const league = getSelectedLeague().toUpperCase();
    return (
        <>
            <h3 style={{ margin: '0 0 10px' }}>Widget Type</h3>
            <Box display={'flex'} alignItems="center" justifyContent="center">
                <Select
                    style={{ height: 36 }}
                    color="secondary"
                    variant="outlined"
                    fullWidth
                    value={widgetType}
                    onChange={handleChange}>
                    <MenuItem value={WIDGET_TYPE.UPCOMING_MATCHUPS}>Upcoming {league} Games (All)</MenuItem>
                    <MenuItem value={WIDGET_TYPE.SINGLE_TEAM}>Upcoming {league} Games (Team)</MenuItem>
                    <MenuItem value={WIDGET_TYPE.SINGLE_MATCHUP}>Single {league} Game</MenuItem>
                </Select>
            </Box>
        </>
    );
}
