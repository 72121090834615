import React, { useContext, useEffect, useRef, useState } from 'react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { WidgetBuilderContext, WIDGET_TYPE } from './WidgetBuilderProvider';
import { makeStyles } from '@material-ui/core/styles';
import { WIDGET_DEMO_ENDPOINT } from '../../lib/endpoints';
import WidgetPlaceholder from './WidgetPlaceholder';
import { extractIdentifier } from '../../lib/utilities';
import { WidgetBuilderAffiliateContext } from './WidgetBuilderAffiliateProvider';

const useStyles = makeStyles((theme) => ({
    iframe: {
        border: 'none',
    },
}));

export default function WidgetPreview() {
    const {
        widgetSize,
        selectedLeagueSelectedGame,
        getSelectedLeague,
        selectedLeagueSelectedTeam,
        oddsFormat,
        widgetType,
        oddsType,
        update,
    } = useContext(WidgetBuilderContext);

    const { currentAffiliate } = useContext(WidgetBuilderAffiliateContext);

    const classes = useStyles();

    const dimensions = widgetSize.split('x');
    const widgetWidth = dimensions[0];
    const widgetHeight = dimensions[1];

    const league = getSelectedLeague();

    const previewPayload = {
        widgetType,
        widgetWidth,
        widgetHeight,
        oddsFormat,
        oddsType,
        league,
        affiliate: {
            company_logo: currentAffiliate.company_logo,
            company_color: currentAffiliate.company_color,
            company_name: currentAffiliate.company_name,
            sportsbooks: currentAffiliate.sportsbooks,
        },
    };

    if (selectedLeagueSelectedGame) {
        previewPayload.gameID = extractIdentifier(selectedLeagueSelectedGame._id);
    }

    if (selectedLeagueSelectedTeam) {
        previewPayload.teamCode = selectedLeagueSelectedTeam._id;
    }

    const previewURL = WIDGET_DEMO_ENDPOINT(previewPayload);

    const EmbedButton = () => {
        return (
            <Box mt={5}>
                <Button
                    onClick={() => update('embedModalOpen', true)}
                    disableElevation
                    variant="contained"
                    color="secondary">
                    Get Embed Code
                </Button>
            </Box>
        );
    };

    return (
        <Box p={4} display={'flex'} justifyContent="center" style={{ minHeight: 550 }}>
            <Box m={5} display="flex" alignItems="center" flexDirection="column" justifyContent="center">
                {widgetType === WIDGET_TYPE.SINGLE_MATCHUP && (
                    <>
                        {selectedLeagueSelectedGame ? (
                            <>
                                <iframe
                                    className={classes.iframe}
                                    src={previewURL}
                                    width={widgetWidth}
                                    height={widgetHeight}
                                />
                                <EmbedButton />
                            </>
                        ) : (
                            <>
                                <WidgetPlaceholder width={widgetWidth} height={widgetHeight} />
                                <Typography variant="subtitle1">No game selected</Typography>
                            </>
                        )}
                    </>
                )}

                {widgetType === WIDGET_TYPE.SINGLE_TEAM && (
                    <>
                        {selectedLeagueSelectedTeam ? (
                            <>
                                <iframe
                                    className={classes.iframe}
                                    src={previewURL}
                                    width={widgetWidth}
                                    height={widgetHeight}
                                />
                                <EmbedButton />
                            </>
                        ) : (
                            <>
                                <WidgetPlaceholder width={widgetWidth} height={widgetHeight} />
                                <Typography variant="subtitle1">No team selected</Typography>
                            </>
                        )}
                    </>
                )}

                {WIDGET_TYPE.UPCOMING_MATCHUPS === widgetType && (
                    <>
                        <iframe className={classes.iframe} src={previewURL} width={widgetWidth} height={widgetHeight} />
                        <EmbedButton />
                    </>
                )}
            </Box>
        </Box>
    );
}
