import React, { useContext, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { WidgetBuilderContext } from './WidgetBuilderProvider';
import Dialog from '@material-ui/core/Dialog';
import CircularProgress from '@material-ui/core/CircularProgress';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { extractIdentifier, KEVEL_AD_SIZE_MAP } from '../../lib/utilities';
import { WidgetBuilderAffiliateContext } from './WidgetBuilderAffiliateProvider';
import { useDataProvider, useNotify } from 'ra-core';
import { LinearProgress } from 'react-admin';

const useStyles = makeStyles((theme) => ({
    code: {
        fontFamily: 'monospace',
        wordBreak: 'break-all',
        fontSize: 12,
    },
    codeBox: {
        backgroundColor: '#efefef',
        textAlign: 'left',
    },
}));

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

export default function EmbedModal() {
    const classes = useStyles();
    const {
        embedModalOpen,
        update,
        oddsFormat,
        oddsType,
        widgetSize,
        generateUniqueIDFromState,
        selectedLeagueSelectedGame,
        selectedLeagueSelectedTeam,
        getSelectedLeague,
        widgetType,
    } = useContext(WidgetBuilderContext);

    const { extractAffiliateWidgetProps, currentAffiliate } = useContext(WidgetBuilderAffiliateContext);

    const dataProvider = useDataProvider();

    const [loading, setLoading] = useState(0);
    const [embedAdId, setEmbedAdId] = useState(null);
    const [progressMessage, setProgressMessage] = useState('Generating embed code...');

    const affiliate = extractAffiliateWidgetProps();

    const handleClose = () => update('embedModalOpen', false);

    const dimensions = widgetSize.split('x');
    const widgetWidth = dimensions[0];
    const widgetHeight = dimensions[1];

    const uniqueName = generateUniqueIDFromState();

    const withGameID = selectedLeagueSelectedGame ? extractIdentifier(selectedLeagueSelectedGame._id) : '';
    const withTeamCode = selectedLeagueSelectedTeam ? selectedLeagueSelectedTeam._id : '';

    const scriptPayload = `
            (function (w, d, s, o, f, js, fjs) {
                w[o] = w[o] || function () { (w[o].q = w[o].q || []).push(arguments) };
                js = d.createElement(s), fjs = d.getElementsByTagName(s)[0];
                js.id = o; js.src = f; js.async = 1; fjs.parentNode.insertBefore(js, fjs);
            }(window, document, "script", "${uniqueName}", "https://dsa.system114.info/widget.js"));

            ${uniqueName}("init", {
                widgetWidth: ${widgetWidth},
                widgetHeight: ${widgetHeight}, 
                oddsFormat: "${oddsFormat}",
                elementID: "${uniqueName}_id",
                oddsType: "${oddsType}",
                league: "${getSelectedLeague()}",
                affiliate: ${JSON.stringify(affiliate)}, 
                widgetType: "${widgetType}"
                ${withGameID ? `, gameID: "${withGameID}"` : ''}
                ${withTeamCode ? `, teamCode: "${withTeamCode}"` : ''}
            });
        `.replace(/\s/g, '');

    const handleCreateCreative = async () => {
        setLoading(10);
        const creative = await dataProvider.kevelCreate('creative', {
            Title: `${uniqueName}_widget`,
            AdTypeId: KEVEL_AD_SIZE_MAP[`${widgetWidth}x${widgetHeight}`],
            Url: 'https://WHATISTHISURL.com', // TODO ??
            IsDeleted: false,
            IsActive: true,
            IsHTMLJS: true,
            ScriptBody: scriptPayload,
        });
        if (creative.data) {
            const creativeID = creative.data.Id;
            const { kevel_site_id, kevel_flight_id } = currentAffiliate;
            setLoading(50);
            const converted = await dataProvider.convertKevelCreative(creativeID, kevel_site_id, kevel_flight_id);
            if (converted.data) {
                setEmbedAdId(converted.data.Id);
            } else {
                setLoading(0);
                setProgressMessage('Something went wrong converting the creative.');
            }
            setLoading(100);
        } else {
            setLoading(0);
            setProgressMessage('Something went wrong creating the creative.');
        }
    };
    const generateEmbedCode = () => {
        const KEVEL_NETWORK_ID = 10770;
        return `
            <script>
                (async function () {
                    try {
                        const res = await fetch("https://content.system110.info/api/v2", {
                            method: "POST",
                            headers: new Headers({ "Content-Type": "application/json" }),
                            body: JSON.stringify({
                                placements: [
                                    {
                                        divName: "widget",
                                        networkId: ${KEVEL_NETWORK_ID},
                                        siteId: "${currentAffiliate.kevel_site_id}",
                                        adTypes: [${KEVEL_AD_SIZE_MAP[`${widgetWidth}x${widgetHeight}`]}],
                                        count: 1,
                                        adId: ${embedAdId},
                                    },
                                ],
                            }),
                        });
                        const data = await res.json();
                        const t = document.getElementById("${uniqueName}_id");
                        if (data.decisions.widget !== null) {
                            const ws = data.decisions.widget[0].contents[0].body;
                            var ns = document.createElement("script");
                            var is = document.createTextNode(ws);
                            ns.appendChild(is);
                            t.appendChild(ns);
                        } else{
                            t.style.display = "none";
                        }
                    } catch (e) {
                        console.log(e);
                        t.style.display = "none";
                    }
                })();
            </script>
            <div style="width:${widgetWidth}px;height:${widgetHeight}px;" id="${uniqueName}_id"></div>
        `;
    };

    const embedCodeRef = useRef(null);

    const notify = useNotify();

    return (
        <Dialog fullWidth maxWidth="md" open={embedModalOpen}>
            <DialogTitle onClose={handleClose}>Widget Embed Code</DialogTitle>
            <DialogContent dividers>
                {loading === 0 && (
                    <Box
                        minHeight={100}
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        justifyContent="center">
                        <Button autoFocus onClick={handleCreateCreative} color="secondary" variant="outlined">
                            Generate Creative
                        </Button>
                    </Box>
                )}
                {embedAdId && (
                    <Box display="flex" flexDirection="column">
                        <p style={{ textAlign: 'center' }}>
                            Copy &amp; Paste this code on your website to display widget
                        </p>
                        <Box p={2} m={2} className={classes.codeBox}>
                            <code ref={embedCodeRef} className={classes.code}>
                                {generateEmbedCode(embedAdId)}
                            </code>
                        </Box>
                        <Button
                            style={{ margin: '20px auto' }}
                            onClick={() => {
                                notify('Copied to clipboard');
                                navigator.clipboard.writeText(embedCodeRef.current.innerText);
                            }}
                            variant="outlined">
                            Copy To Clipboard
                        </Button>
                    </Box>
                )}
                {loading > 0 && !embedAdId && (
                    <Box
                        minHeight={200}
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        justifyContent="center">
                        <CircularProgress color="secondary" />
                        <br />
                        <LinearProgress color={'secondary'} variant="determinate" value={loading} />
                        <p>{progressMessage}</p>
                    </Box>
                )}
            </DialogContent>
        </Dialog>
    );
}
