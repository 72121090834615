import { usePermissions } from 'ra-core';
import React, { useContext } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { Title, useAuthState, Loading, useNotify, useLogout } from 'react-admin';
import { Redirect } from 'react-router';
import { WidgetBuilderAffiliateContext } from '../components/WidgetBuilder/WidgetBuilderAffiliateProvider';

const Profile = () => {
    document.title = 'DSA - Profile';
    const { loading, authenticated } = useAuthState();
    const context = useContext(WidgetBuilderAffiliateContext);
    const notify = useNotify();
    const { permissions } = usePermissions();
    const logout = useLogout();

    if (loading) {
        return <Loading />;
    }

    if (!authenticated) {
        return <Redirect to="/login" />;
    }

    if (permissions && permissions[0] === 'admin') {
        return (
            <Card>
                <Title title="User Profile" />
                <CardContent>
                    <div>
                        fetch user profile information
                        <br />
                        <br />
                        <button onClick={logout}>Log Out</button>
                    </div>
                </CardContent>
            </Card>
        );
    }
};

export default Profile;
