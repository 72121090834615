import React, { useEffect, useState } from 'react';
import { CircularProgress, Box } from '@material-ui/core';
import { useDataProvider } from 'react-admin';
import { alphabetical } from '../../lib/utilities';

export const GeoOptionsContext = React.createContext();

const GeoOptionsProvider = ({ children }) => {
    const dataProvider = useDataProvider();

    const [countryOptions, setCountryOptions] = useState(null);
    const [regionOptions, setRegionOptions] = useState(null);

    const getCountryOptions = async () => {
        try {
            const res = await dataProvider.getCountriesFromKevel();
            if (res.data && res.data.length) {
                setCountryOptions(res.data.sort(alphabetical));
            }
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(async () => {
        if (!countryOptions) {
            getCountryOptions();
        }
    }, []);

    return (
        <GeoOptionsContext.Provider
            value={{
                countryOptions,
                regionOptions,
                setCountryOptions,
                setRegionOptions,
                getRegionOptionsForCountry: async (country) => {
                    const selectedCountry = countryOptions.find((x) => x.Code === country);
                    setRegionOptions(Object.values(selectedCountry.Regions).sort(alphabetical));
                },
            }}>
            {countryOptions ? (
                children
            ) : (
                <Box p={3}>
                    <CircularProgress size={26} />
                </Box>
            )}
        </GeoOptionsContext.Provider>
    );
};

export default GeoOptionsProvider;
