import React from 'react';
import { UPCOMING_GAMES_ENDPOINT, LEAGUE_TEAMS_ENDPOINT } from '../../lib/endpoints';
import { extractIdentifier } from '../../lib/utilities';

export const WidgetBuilderContext = React.createContext();

export const WIDGET_TYPE = {
    UPCOMING_MATCHUPS: 'upcoming_matchups',
    SINGLE_MATCHUP: 'single_matchup',
    SINGLE_TEAM: 'single_team',
};

export const ODDS_FORMAT = {
    AMERICAN: 'american',
    DECIMAL: 'decimal',
    FRACTIONAL: 'fraction',
};

export const ODDS_TYPE = {
    MONEYLINE: 'moneyline',
    TOTAL: 'overunder',
    SPREAD: 'pointspread',
};

export const WIDGET_SIZES = {
    ['300x50']: '300x50',
    ['300x250']: '300x250',
    ['300x600']: '300x600',
    ['300x1050']: '300x1050',
    ['320x50']: '320x50',
    ['600x400']: '600x400',
    ['728x90']: '728x90',
};

const initialState = {
    selectedLeagueIndex: 0, // tab index
    loading: false, // fetching data after user interactions, etc
    leagues: [], // from active seasons endpoint
    selectedLeagueGames: [], // array of individual games
    selectedLeagueSelectedGame: null, // selected individual game for the current league
    selectedLeagueTeams: [], // array of individual teams
    selectedLeagueSelectedTeam: null, // selected individual team for the current league
    widgetType: WIDGET_TYPE.UPCOMING_MATCHUPS,
    embedModalOpen: false,
    oddsFormat: ODDS_FORMAT.AMERICAN,
    oddsType: ODDS_TYPE.MONEYLINE,
    widgetSize: WIDGET_SIZES['300x250'],
};

class WidgetBuilderProvider extends React.Component {
    state = initialState;

    getSelectedLeague = () => this.state.leagues[this.state.selectedLeagueIndex].league;

    fetchGamesForCurrentState = async () => {
        const currentLeague = this.getSelectedLeague();
        const oddsType = this.state.oddsType;
        const oddsFormat = this.state.oddsFormat;
        const ODDS_ENDPOINT = UPCOMING_GAMES_ENDPOINT(currentLeague, oddsType, oddsFormat);
        const data = await fetch(ODDS_ENDPOINT);
        const games = await data.json();
        return games;
    };

    fetchTeamsForCurrentState = async () => {
        const currentLeague = this.getSelectedLeague();
        const ODDS_ENDPOINT = LEAGUE_TEAMS_ENDPOINT(currentLeague);
        const data = await fetch(ODDS_ENDPOINT);
        const teams = await data.json();
        return teams;
    };

    // Each instance of a widget embed needs a unique ID.
    generateUniqueIDFromState = () => {
        const { widgetType, selectedLeagueSelectedGame, selectedLeagueSelectedTeam, widgetSize } = this.state;
        let withSingleGame = '';
        if (selectedLeagueSelectedGame) {
            withSingleGame = '_' + extractIdentifier(selectedLeagueSelectedGame._id);
        }
        let withSingleTeam = '';
        if (selectedLeagueSelectedTeam) {
            withSingleTeam = '_' + selectedLeagueSelectedTeam._id + selectedLeagueSelectedTeam.nick;
        }
        const league = this.getSelectedLeague();
        return `dsa_${widgetType}_${league}_${widgetSize}${withSingleGame}${withSingleTeam}`;
    };

    render() {
        return (
            <WidgetBuilderContext.Provider
                value={{
                    ...this.state,
                    update: (key, payload) => this.setState({ [key]: payload }),
                    getSelectedLeague: this.getSelectedLeague,
                    generateUniqueIDFromState: this.generateUniqueIDFromState,
                    updateWidgetType: async (type) => {
                        if (!this.state.loading) {
                            this.setState(
                                {
                                    widgetType: type,
                                    loading: true,
                                    selectedLeagueTeams: [],
                                    selectedLeagueGames: [],
                                    selectedLeagueSelectedTeam: null,
                                    selectedLeagueSelectedGame: null,
                                    widgetSize: initialState.widgetSize, // reset since some are restricted
                                },
                                async () => {
                                    if (WIDGET_TYPE.SINGLE_MATCHUP === type) {
                                        const games = await this.fetchGamesForCurrentState();
                                        this.setState({ selectedLeagueGames: games });
                                    }
                                    if (WIDGET_TYPE.SINGLE_TEAM === type) {
                                        const teams = await this.fetchTeamsForCurrentState();
                                        this.setState({ selectedLeagueTeams: teams });
                                    }

                                    this.setState({ loading: false });
                                }
                            );
                        }
                    },

                    updateOddsType: (type) => {
                        if (!this.state.loading && this.state.oddsType !== type) {
                            this.setState({ oddsType: type, loading: true }, async () => {
                                const games = await this.fetchGamesForCurrentState();
                                this.setState({ selectedLeagueGames: games, loading: false });
                            });
                        }
                    },
                    updateOddsFormat: (format) => {
                        if (!this.state.loading && this.state.oddsFormat !== format) {
                            this.setState({ oddsFormat: format, loading: true }, async () => {
                                const games = await this.fetchGamesForCurrentState();
                                this.setState({ selectedLeagueGames: games, loading: false });
                            });
                        }
                    },
                    handleChangeLeague: (index) => {
                        this.setState({
                            widgetType: WIDGET_TYPE.UPCOMING_MATCHUPS,
                            widgetSize: initialState.widgetSize, // reset since some are restricted
                            selectedLeagueTeams: [],
                            selectedLeagueGames: [],
                            selectedLeagueSelectedGame: null,
                            selectedLeagueSelectedTeam: null,
                            selectedLeagueIndex: index,
                        });
                    },
                }}>
                {this.props.children}
            </WidgetBuilderContext.Provider>
        );
    }
}

export default WidgetBuilderProvider;
