import React, { useContext } from 'react';
import { MenuItemLink, usePermissions } from 'react-admin';
import List from '@material-ui/core/List';
import WidgetsIcon from '@material-ui/icons/Widgets';
import PeopleAltRounded from '@material-ui/icons/PeopleAltRounded';
import { WidgetBuilderAffiliateContext } from '../WidgetBuilder/WidgetBuilderAffiliateProvider';

export const CustomMenu = (props) => {
    const { permissions, loading } = usePermissions();

    return (
        <div style={{ height: '100%', borderRadius: 0 }}>
            <List style={{ width: 200 }}>
                <MenuItemLink to="/widgets" primaryText="Widget Builder" leftIcon={<WidgetsIcon />} {...props} />
                {!loading && permissions[0] === 'admin' && (
                    <MenuItemLink
                        to="/affiliates"
                        primaryText="Manage Affiliates"
                        leftIcon={<PeopleAltRounded />}
                        exact
                        {...props}
                    />
                )}
            </List>
        </div>
    );
};
