import { fetchUtils } from 'react-admin';
import { stringify } from 'query-string';
import Auth from '@aws-amplify/auth';
import { API_URL, KEVEL_API, KEVEL_LAMBDA__PROXY, ODDS_SOURCES_ENDPOINT } from './lib/endpoints';

const XL_MEDIA_ADVERTISER_ID = 1798096;

const fetchJson = async (url, options = {}) => {
    // Auth.currentSession()
    //     .then((session) => {
    //         const token = session.getAccessToken().getJwtToken();
    //         if (token) {
    //             options.headers = {
    //                 ['Authorization']: token,
    //             };
    //         }
    //         return fetchUtils.fetchJson(url, options);
    //     })
    //     .catch((e) => {
    //         console.log(e);
    //     });
    // console.log(fetchJson);

    if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json' });
    }
    try {
        const token = await (await Auth.currentSession()).getIdToken().getJwtToken();
        if (token) {
            options.headers.set('Authorization', token);
        }
    } catch (err) {
        console.log(err);
    }
    return fetchUtils.fetchJson(url, options);
};

const httpClient = fetchJson;

export default {
    getList: (resource, params) => {
        const query = '';
        // console.log(params, '====');
        // if (params.pagination && params.sort) {
        //     const { page, perPage } = params.pagination;
        //     const { field, order } = params.sort;
        //     query = {
        //         sort: JSON.stringify([field, order]),
        //         range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
        //         filter: JSON.stringify(params.filter),
        //     };
        // }
        const url = `${API_URL}/${resource}/list?${stringify(query)}`;

        return httpClient(url).then(({ headers, json }) => ({
            data: json,
            total: 100, //parseInt(headers.get('content-range').split('/').pop(), 10),
        }));
    },

    getOne: (resource, params) => {
        const url = `${API_URL}/${resource}/${params.id}`;
        return httpClient(url).then(({ json }) => ({
            data: json,
        }));
    },

    getMany: (resource, params) => {
        const query = {
            filter: JSON.stringify({ id: params.ids }),
        };
        const url = `${API_URL}/${resource}?${stringify(query)}`;
        return httpClient(url).then(({ json }) => ({ data: json }));
    },

    getManyReference: (resource, params) => {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        const query = {
            sort: JSON.stringify([field, order]),
            range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
            filter: JSON.stringify({
                ...params.filter,
                [params.target]: params.id,
            }),
        };
        const url = `${API_URL}/${resource}?${stringify(query)}`;

        return httpClient(url).then(({ headers, json }) => ({
            data: json,
            total: parseInt(headers.get('content-range').split('/').pop(), 10),
        }));
    },

    update: (resource, params) =>
        httpClient(`${API_URL}/${resource}/${params.id}`, {
            method: 'PUT',
            body: JSON.stringify(params.data),
        }).then(({ json }) => ({ data: json })),

    updateMany: (resource, params) => {
        const query = {
            filter: JSON.stringify({ id: params.ids }),
        };
        return httpClient(`${API_URL}/${resource}?${stringify(query)}`, {
            method: 'PUT',
            body: JSON.stringify(params.data),
        }).then(({ json }) => ({ data: json }));
    },

    create: (resource, params) =>
        httpClient(`${API_URL}/${resource}`, {
            method: 'POST',
            body: JSON.stringify(params.data),
        }).then(({ json }) => ({
            data: { ...params.data, id: json.id },
        })),

    delete: (resource, params) =>
        httpClient(`${API_URL}/${resource}/${params.id}`, {
            method: 'DELETE',
        }).then(({ json }) => ({ data: json })),

    deleteMany: (resource, params) => {
        const query = {
            filter: JSON.stringify({ id: params.ids }),
        };
        return httpClient(`${API_URL}/${resource}?${stringify(query)}`, {
            method: 'DELETE',
        }).then(({ json }) => ({ data: json }));
    },

    getCountriesFromKevel: () => {
        return httpClient(KEVEL_LAMBDA__PROXY, {
            method: 'POST',
            body: JSON.stringify({
                method: 'GET',
                kevelUrl: `${KEVEL_API}/countries`,
            }),
        })
            .then(({ json }) => {
                return { data: json };
            })
            .catch((err) => {
                return err;
            });
    },

    getRegionsForCountryFromKevel: (code) => {
        return httpClient(KEVEL_LAMBDA__PROXY, {
            method: 'POST',
            body: JSON.stringify({
                method: 'GET',
                kevelUrl: `${KEVEL_API}/country/${code}/regions`,
            }),
        })
            .then(({ json }) => {
                return { data: json };
            })
            .catch((err) => {
                return err;
            });
    },

    convertKevelCreative: (creativeID, siteID, flightID) => {
        return httpClient(KEVEL_LAMBDA__PROXY, {
            method: 'POST',
            body: JSON.stringify({
                method: 'POST',
                kevelUrl: `${KEVEL_API}/flight/${flightID}/creative`,
                kevelBody: {
                    Creative: { Id: creativeID },
                    FlightId: flightID,
                    IsActive: true,
                    SiteID: siteID,
                },
            }),
        })
            .then(({ json }) => {
                return { data: json };
            })
            .catch((err) => {
                return err;
            });
    },

    kevelCreate: (resource, bodyPayload) => {
        return httpClient(KEVEL_LAMBDA__PROXY, {
            method: 'POST',
            body: JSON.stringify({
                method: 'POST',
                kevelUrl: `${KEVEL_API}/${resource}`,
                kevelBody: {
                    AdvertiserId: XL_MEDIA_ADVERTISER_ID,
                    ...bodyPayload,
                },
            }),
        })
            .then(({ json }) => {
                return { data: json };
            })
            .catch((err) => {
                return err;
            });
    },

    kevelUpdate: (resource, bodyPayload) => {
        return httpClient(KEVEL_LAMBDA__PROXY, {
            method: 'POST',
            body: JSON.stringify({
                method: 'PUT',
                kevelUrl: `${KEVEL_API}/${resource}`,
                kevelBody: {
                    AdvertiserId: XL_MEDIA_ADVERTISER_ID,
                    ...bodyPayload,
                },
            }),
        })
            .then(({ json }) => {
                return { data: json };
            })
            .catch((err) => {
                return err;
            });
    },

    kevelGet: (resource) => {
        return httpClient(KEVEL_LAMBDA__PROXY, {
            method: 'POST',
            body: JSON.stringify({
                method: 'GET',
                kevelUrl: `${KEVEL_API}/${resource}`,
                kevelBody: {
                    AdvertiserId: XL_MEDIA_ADVERTISER_ID,
                },
            }),
        })
            .then(({ json }) => {
                return { data: json };
            })
            .catch((err) => {
                return err;
            });
    },

    getBookOptionsFromFeeds: () => {
        return httpClient(ODDS_SOURCES_ENDPOINT)
            .then(({ json }) => ({ data: json }))
            .catch((err) => err);
    },

    gets3PresignedPostURL: (key) => {
        return httpClient(`${API_URL}/aws/s3presigned`, {
            method: 'POST',
            body: JSON.stringify({ key }),
        })
            .then(({ json }) => ({ data: json }))
            .catch((err) => err);
    },
};
