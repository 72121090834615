import * as React from 'react';
import { Resource } from 'react-admin';
import { Admin } from '@react-admin/ra-enterprise';
import { ThemeProvider } from '@material-ui/core/styles';
import authProvider from './authProvider';
import dataProvider from './dataProvider';
import Login from './pages/LoginPage';
import { customTheme } from './theme';
import routes from './routes';
import PeopleIcon from '@material-ui/icons/People';
import CustomLayout from './components/General/Layout';
import { AffiliateList } from './components/Affiliates/AffiliateList';
import AffiliateEdit from './components/Affiliates/AffiliateEdit';
import AffiliateCreate from './components/Affiliates/AffiliateCreate';
import AffiliateShow from './components/Affiliates/AffiliateShow';
import UserEdit from './components/Users/UserEdit';
import UserCreate from './components/Users/UserCreate';
import Amplify from 'aws-amplify';
import Dashboard from './pages/Dashboard';

Amplify.configure({
    Auth: {
        region: process.env.REACT_APP_REGION,
        userPoolId: process.env.REACT_APP_USER_POOL_ID,
        userPoolWebClientId: process.env.REACT_APP_APP_CLIENT_ID,
    },
});

const App = () => (
    <ThemeProvider theme={customTheme}>
        <Admin
            disableTelemetry
            layout={CustomLayout}
            customRoutes={routes}
            loginPage={Login}
            lightTheme={customTheme}
            darkTheme={customTheme}
            authProvider={authProvider}
            dataProvider={dataProvider}>
            {(permissions) => {
                const isAdmin = permissions[0] === 'admin';
                const adminResources = [
                    <Resource
                        icon={PeopleIcon}
                        key="affiliates"
                        name="affiliates"
                        show={AffiliateShow}
                        create={AffiliateCreate}
                        list={AffiliateList}
                        edit={AffiliateEdit}
                    />,
                    <Resource icon={PeopleIcon} key="users" name="users" />,
                ];
                // Dummy resource required since custom dashboard is all regular users can access?
                return isAdmin ? adminResources : [<Resource key="widgets" name="widgets" />];
            }}
        </Admin>
    </ThemeProvider>
);

export default App;
