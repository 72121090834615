import React, { useCallback, useContext, useEffect, useState } from 'react';
import { EditButton, useDataProvider, ShowBase, useShowController, useNotify, useRedirect } from 'react-admin';
import { Box, Card, Chip } from '@material-ui/core';
import {
    Typography,
    TableBody,
    Table,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Button,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/CancelOutlined';
import AccountBoxIcon from '@material-ui/icons/AccountCircle';
import EmailIcon from '@material-ui/icons/MailOutline';
import { AffiliateBreadcrumbs } from './AffiliateBreadcrumbs';
import AffiliateBar from './AffiliateBar';
import { hexIsTooLight } from '../../lib/utilities';
import { WidgetBuilderAffiliateContext } from '../WidgetBuilder/WidgetBuilderAffiliateProvider';
import WidgetsIcon from '@material-ui/icons/Widgets';
import WidgetLoaderSkeleton from '../WidgetBuilder/WidgetLoaderSkeleton';
import UserCreateDialog from '../Users/UserCreateDialog';
import GeoTable from './GeoTable';
import GeoOptionsProvider from './GeoOptionsProvider';

const AffiliateShow = (props) => {
    // Hooks
    const redirect = useRedirect();
    const notify = useNotify();
    const dataProvider = useDataProvider();
    const { setAffiliate } = useContext(WidgetBuilderAffiliateContext);
    const { record, basePath } = useShowController(props);

    // UI State
    const [userCreateDialog, setUserCreateDialog] = useState(false);
    const [version, setVersion] = useState(0);
    const [flight, setFlight] = useState(null);

    useEffect(async () => {
        if (record) {
            const res = await dataProvider.kevelGet(`flight/${record.kevel_flight_id}`);
            if (res.data.Id) {
                setFlight(res.data);
            } else {
                notify('There was an error retrieving the flight from Kevel');
            }
        }
    }, [record]);

    // Handlers
    const handleChange = useCallback(() => setVersion(version + 1), [version]);

    const handleCreateNewUser = () => setUserCreateDialog(true);

    const handleDeleteUser = async (user) => {
        try {
            const { data } = await dataProvider.delete('affiliates/users', { id: user.cognitoID });
            if (data) {
                notify('User deleted');
                handleChange();
            } else {
                notify('There was an error deleting that user...');
            }
        } catch (err) {
            console.log(err);
            notify(err);
        }
    };

    const handleShowInBuilder = (affiliate) => {
        setAffiliate(affiliate);
        redirect('/widgets');
    };

    if (record && flight) {
        const companyColorIsLight = hexIsTooLight(record.company_color);
        return (
            <ShowBase {...props} key={version}>
                <Box>
                    <Box pt={2}>
                        <AffiliateBreadcrumbs />
                    </Box>

                    <Box position="relative" mt={2}>
                        <AffiliateBar affiliate={record} />
                        <Box
                            style={{
                                position: 'absolute',
                                top: '50%',
                                right: 20,
                                transform: 'translateY(-50%)',
                            }}>
                            <Button
                                style={{
                                    marginRight: 15,
                                    color: companyColorIsLight ? '#000' : '#fff',
                                }}
                                onClick={() => handleShowInBuilder(record)}
                                size="small"
                                variant="text">
                                <WidgetsIcon /> &nbsp; View In Builder
                            </Button>
                            <EditButton
                                style={{ color: companyColorIsLight ? '#000' : '#fff' }}
                                basePath={basePath}
                                record={record}
                            />
                        </Box>
                    </Box>
                    <Box flex={1}>
                        <Box display="flex">
                            {(record.contact_email || record.contact_name) && (
                                <Box flex={1}>
                                    <h3 style={{ marginTop: 0 }}>Contact Info</h3>
                                    <Card style={{ padding: 15, marginRight: 20, flex: 1, height: 65 }}>
                                        <Box display="flex" alignItems="center" mb={2}>
                                            <AccountBoxIcon /> &nbsp;&nbsp;
                                            <Typography>{record.contact_name}</Typography>
                                        </Box>
                                        <Box display="flex" alignItems="center">
                                            <EmailIcon /> &nbsp;&nbsp;
                                            <Typography>{record.contact_email}</Typography>
                                        </Box>
                                    </Card>
                                </Box>
                            )}
                            <Box flex={3}>
                                <h3 style={{ marginTop: 0 }}>Sportsbooks</h3>
                                <Card style={{ padding: 15, display: 'flex', height: 65 }}>
                                    {record.sportsbooks &&
                                        record.sportsbooks.map((name) => {
                                            return <Chip style={{ marginRight: 5 }} key={name} label={name}></Chip>;
                                        })}
                                </Card>
                            </Box>
                        </Box>
                    </Box>

                    {flight.GeoTargeting.length > 0 && (
                        <Box mt={3}>
                            <h3 style={{ marginBottom: 10 }}>Geo Targeting</h3>
                            <Card>
                                <GeoOptionsProvider>
                                    <GeoTable geos={flight.GeoTargeting} readOnly />
                                </GeoOptionsProvider>
                            </Card>
                        </Box>
                    )}

                    <Box flex={1} mt={2}>
                        <Box display={'flex'} alignItems={'center'}>
                            <Box mb={2}>
                                <h3 style={{ marginBottom: 10 }}>Users</h3>
                                <small>
                                    <i>
                                        The following users will only be able to access the widget builder for this
                                        affiliate.
                                    </i>
                                </small>
                            </Box>
                            <Box style={{ marginLeft: 'auto' }}>
                                <Button
                                    size="small"
                                    onClick={() => handleCreateNewUser(record)}
                                    variant="outlined"
                                    startIcon={<AddIcon />}>
                                    User
                                </Button>
                            </Box>
                        </Box>
                        {record.users && record.users.length > 0 && (
                            <Card>
                                <TableContainer>
                                    <Table size="small">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Name</TableCell>
                                                <TableCell>Email</TableCell>
                                                <TableCell></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {record.users.map((user) => {
                                                return (
                                                    <UserTableRow
                                                        handleDelete={handleDeleteUser}
                                                        key={user.id}
                                                        user={user}
                                                    />
                                                );
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Card>
                        )}

                        {!record.users && <WidgetLoaderSkeleton height={140} />}

                        {record.users && record.users.length === 0 && (
                            <Box height={100} display="flex" alignItems="center">
                                <Typography>No users</Typography>
                            </Box>
                        )}
                    </Box>
                    <UserCreateDialog
                        onChange={handleChange}
                        affiliate={record}
                        handleClose={setUserCreateDialog}
                        open={userCreateDialog}
                    />
                </Box>
            </ShowBase>
        );
    }
    return (
        <Box mt={2}>
            <WidgetLoaderSkeleton />
        </Box>
    );
};

const UserTableRow = ({ user, handleDelete }) => {
    const { id, name, email } = user;
    const [confirmDeleteUser, setConfirmDeleteUser] = useState(false);

    return (
        <TableRow key={id}>
            <TableCell>{name}</TableCell>
            <TableCell>{email}</TableCell>
            <TableCell align="right" style={{ width: 200 }}>
                {confirmDeleteUser ? (
                    <Button variant="outlined" color="secondary" onClick={() => handleDelete(user)}>
                        Delete User?
                    </Button>
                ) : (
                    <Button onClick={() => setConfirmDeleteUser(true)}>
                        <RemoveIcon />
                    </Button>
                )}
            </TableCell>
        </TableRow>
    );
};

export default AffiliateShow;
