import React, { Fragment, useContext, useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import ListItem from '@material-ui/core/ListItem';
import CardActionArea from '@material-ui/core/CardActionArea';
import { WidgetBuilderContext } from './WidgetBuilderProvider';
import { makeStyles } from '@material-ui/core/styles';
import { format } from 'date-fns';

const useStyles = makeStyles((theme) => ({
    item: {
        paddingLeft: 0,
        paddingRight: 0,
    },
    list: {
        padding: 0,
        maxHeight: 500,
        overflowY: 'scroll',
    },
}));

export default function WidgetGamesList() {
    const classes = useStyles();

    const { selectedLeagueGames, loading, selectedLeagueSelectedGame, update, getSelectedLeague } =
        useContext(WidgetBuilderContext);

    const handleClickGame = (game) => update('selectedLeagueSelectedGame', game);

    const [sortedGames, setSortedGames] = useState(selectedLeagueGames);

    useEffect(() => {
        if (selectedLeagueGames.length > 0) {
            setSortedGames(selectedLeagueGames);
        }
    }, [selectedLeagueGames]);

    return (
        <>
            <h3 style={{ margin: '30px 0 10px' }}>Upcoming {getSelectedLeague().toUpperCase()} Games</h3>
            <Card variant="outlined">
                <Box p={2}>
                    <Box p={2} pt={0}>
                        <TextField
                            onChange={({ target }) => {
                                console.log(target.value);
                                const val = target.value.toLowerCase();
                                setSortedGames(
                                    selectedLeagueGames.filter((game) => {
                                        const { away, home } = game;
                                        if (away.toLowerCase().includes(val) || home.toLowerCase().includes(val)) {
                                            return game;
                                        }
                                    })
                                );
                            }}
                            fullWidth
                            color="secondary"
                            label="Search..."
                        />
                    </Box>
                    <List className={classes.list}>
                        {selectedLeagueGames.length === 0 && !loading && (
                            <Typography variant="subtitle1">No upcoming games</Typography>
                        )}
                        {loading && selectedLeagueGames.length === 0 && (
                            <Box p={5} display="flex" alignItems="center" justifyContent="center">
                                <CircularProgress size={25} color="secondary" />
                            </Box>
                        )}
                        {sortedGames.map((game) => {
                            const { _id, away, home, startDate } = game;
                            const selected = selectedLeagueSelectedGame && selectedLeagueSelectedGame._id === _id;
                            return (
                                <ListItem className={classes.item} key={_id} alignItems="flex-start">
                                    <Card
                                        elevation={0}
                                        style={{
                                            width: '100%',
                                            borderBottom: '1px solid #efefef',
                                            backgroundColor: selected ? '#efefef' : 'initial',
                                        }}>
                                        <CardActionArea onClick={() => handleClickGame(game)}>
                                            <Box display={'flex'} flexDirection={'column'} p={2}>
                                                <h3 style={{ margin: '0 0 5px' }}>{`${away} @ ${home}`}</h3>
                                                <span>
                                                    {format(new Date(startDate + '.000Z'), 'MMMM do, yyyy @ h:mm aa')}
                                                </span>
                                                <Box mt={1}>
                                                    <small style={{ fontSize: 10, color: '#999' }}>{_id}</small>
                                                </Box>
                                            </Box>
                                        </CardActionArea>
                                    </Card>
                                </ListItem>
                            );
                        })}
                    </List>
                </Box>
            </Card>
        </>
    );
}
