import { Box, Card, Typography } from '@material-ui/core';
import React from 'react';
import { hexIsTooLight } from '../../lib/utilities';

export default function AffiliateBar({ affiliate }) {
    return (
        <Card elevation={2} style={{ marginBottom: 20 }}>
            <Box
                display="flex"
                alignItems="center"
                flexDirection="row"
                p={2}
                style={{ backgroundColor: affiliate.company_color }}>
                <img style={{ marginRight: 10 }} src={affiliate.company_logo} width="90" height="30" />
                <Typography style={{ color: hexIsTooLight(affiliate.company_color) ? 'black' : 'white' }} variant="h6">
                    {affiliate.company_name}
                </Typography>
            </Box>
        </Card>
    );
}
