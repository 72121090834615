import merge from 'lodash/merge';
import { createTheme } from '@material-ui/core/styles';
import { defaultTheme } from 'react-admin';
import deepOrange from '@material-ui/core/colors/deepOrange';

export const customTheme = createTheme(
    merge({}, defaultTheme, {
        palette: {
            // type: 'dark',
            primary: {
                // random red
                main: '#efefef',
                light: 'rgba(0,0,0,0.1)',
                dark: 'rgba(0,0,0,0.1)',
            },
            secondary: {
                main: '#d72429',
                light: '#d72429',
                dark: '#d72429',
            },
            error: {
                main: '#df0d34',
                light: '#df0d34',
                dark: '#df0d34',
            },
            contrastThreshold: 3,
            tonalOffset: 0.2,
        },
        // overrides: {
        //     MuiTableRow: {
        //         head: {
        //             '& > th ': {
        //                 backgroundColor: '#212121',
        //                 fontWeight: 'bold',
        //             },
        //         },
        //     },
        // },
    })
);
