import * as React from 'react';
import { useState } from 'react';
import { useLogin, useNotify, Notification } from 'react-admin';
import { TextField, Box, Card, Button, CircularProgress } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import CustomNotification from '../components/General/Notification';
import { loginStyles } from './PasswordReset';
import logo from '../img/DSA-Logo.svg';

const Login = ({ classes }) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const login = useLogin();
    const notify = useNotify();
    const submit = (e) => {
        setLoading(true);
        e.preventDefault();
        login({ email, password }).catch(() => {
            notify('Invalid email or password');
            setLoading(false);
        });
    };

    return (
        <form onSubmit={submit} noValidate>
            <div className={classes.main}>
                <Card className={classes.card}>
                    <Box display={'flex'} pt={3} justifyContent="center">
                        <img src={logo} alt="DSA Logo" />
                    </Box>
                    <div className={classes.form}>
                        <div className={classes.input}>
                            <TextField
                                fullWidth
                                variant={'outlined'}
                                color="secondary"
                                autoFocus
                                name="email"
                                label={'Email'}
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </div>
                        <div className={classes.input}>
                            <TextField
                                fullWidth
                                variant={'outlined'}
                                color="secondary"
                                name="password"
                                label={'Password'}
                                type="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className={classes.actions}>
                        <Button
                            className={classes.submit}
                            variant="contained"
                            type="submit"
                            color="primary"
                            disabled={loading}
                            fullWidth>
                            {loading ? <CircularProgress size={20} thickness={2} /> : 'Sign In'}
                        </Button>
                    </div>
                </Card>
                <br />
                <a href="/#/password-reset">forgot password?</a>
            </div>
            <CustomNotification />
        </form>
    );
};

export default withStyles(loginStyles)(Login);
