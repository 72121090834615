import Auth from '@aws-amplify/auth';
import { useDataProvider } from 'ra-core';
import React, { useEffect, useState } from 'react';

export const UserProviderContext = React.createContext();

// User from DB. Associated with cognito user via sub ID.
const UserProvider = ({ children }) => {
    const [currentUser, setCurrentUser] = useState(null);
    const dataProvider = useDataProvider();

    useEffect(async () => {
        if (!currentUser) {
            const cognitoUser = await Auth.currentAuthenticatedUser();
            const { data } = await dataProvider.getOne('affiliates/users', {
                id: cognitoUser.attributes.sub,
            });
            setCurrentUser(data);
        }
    }, []);

    return (
        <UserProviderContext.Provider
            value={{
                currentUser,
            }}>
            {children}
        </UserProviderContext.Provider>
    );
};

export default UserProvider;
