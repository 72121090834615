import React, { useContext, useEffect, useState } from 'react';
import AddIcon from '@material-ui/icons/AddCircle';
import { Box, TextField, Button, MenuItem } from '@material-ui/core';
import { useNotify } from 'react-admin';
import CircularProgress from '@material-ui/core/CircularProgress';
import { GeoOptionsContext } from './GeoOptionsProvider';

export const GeoInputs = ({ onAdd, geos }) => {
    const [country, setCountry] = useState('');
    const [region, setRegion] = useState('');
    const [isExclude, setIsExclude] = useState('include');
    const notify = useNotify();

    const { countryOptions, regionOptions, getRegionOptionsForCountry } = useContext(GeoOptionsContext);

    useEffect(async () => {
        if (country) {
            getRegionOptionsForCountry(country);
        }
    }, [country]);

    const handleAddGeo = () => {
        let canAdd = true;
        for (let x = 0; x < geos.length; x++) {
            // Do not allow already added country + region combos or conflicting combos.
            if (geos[x].CountryCode === country && geos[x].Region === region) {
                canAdd = false;
                break;
            }
            if (geos[x].CountryCode === country && geos[x].Region === null && region === 'all') {
                canAdd = false;
                break;
            }
            // TODO: review logic here to prevent adding conflicting geo rules?
            // Kevel seems to prevent conflicting geo sets but does allow duplicate rules for some reason.
        }

        if (canAdd) {
            onAdd({
                CountryCode: country,
                Region: region,
                IsExclude: isExclude === 'include' ? false : true,
            });
            setCountry(null);
            setRegion(null);
        } else {
            notify('Invalid region selection', 'warning');
        }
    };

    return (
        <Box display="flex" mt={2}>
            <Box mr={2} flex={1}>
                <TextField
                    fullWidth
                    select
                    size="small"
                    variant="outlined"
                    SelectProps={{
                        value: isExclude === 'include' ? 'include' : 'exclude',
                        onChange: (e) => setIsExclude(e.target.value),
                    }}>
                    <MenuItem value={'include'}>Include</MenuItem>
                    <MenuItem value={'exclude'}>Exclude</MenuItem>
                </TextField>
            </Box>
            <Box mr={2} flex={1}>
                <TextField
                    fullWidth
                    select
                    size="small"
                    variant="outlined"
                    label="Country"
                    SelectProps={{
                        value: country,
                        onChange: (e) => {
                            setCountry(e.target.value);
                            setRegion('all');
                        },
                    }}>
                    {countryOptions.map((option) => {
                        return (
                            <MenuItem key={option.Code} value={option.Code}>
                                {option.Name}
                            </MenuItem>
                        );
                    })}
                </TextField>
            </Box>
            <Box mr={2} flex={1}>
                <TextField
                    fullWidth
                    select
                    size="small"
                    label="Region"
                    variant="outlined"
                    SelectProps={{
                        disabled: !country || (regionOptions && regionOptions.length === 0),
                        value: region,
                        onChange: (e) => setRegion(e.target.value),
                    }}>
                    <MenuItem value={'all'}>All</MenuItem>
                    {regionOptions &&
                        regionOptions.map((option) => {
                            return (
                                <MenuItem key={option.Code} value={option.Code}>
                                    {option.Name}
                                </MenuItem>
                            );
                        })}
                </TextField>
            </Box>
            <Button disabled={!country} style={{ backgroundColor: '#efefef' }} onClick={handleAddGeo}>
                <AddIcon />
            </Button>
        </Box>
    );
};
