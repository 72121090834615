import React from 'react';

export const WidgetBuilderAffiliateContext = React.createContext();

const initialState = {
    currentAffiliate: null,
    adminSelectAffiliateModalOpen: true,
};

class WidgetBuilderAffiliateProvider extends React.Component {
    state = initialState;

    render() {
        return (
            <WidgetBuilderAffiliateContext.Provider
                value={{
                    ...this.state,
                    update: (key, payload) => this.setState({ [key]: payload }),
                    setAffiliate: async (affiliate) => {
                        await this.setState({ currentAffiliate: affiliate });
                    },
                    toggleAdminAffiliateSelectModal: () => {
                        this.setState({ adminSelectAffiliateModalOpen: !this.state.adminSelectAffiliateModalOpen });
                    },
                    extractAffiliateWidgetProps: () => {
                        const currentAffiliate = this.state.currentAffiliate;
                        return {
                            company_logo: currentAffiliate.company_logo,
                            company_color: currentAffiliate.company_color,
                            company_name: currentAffiliate.company_name,
                            sportsbooks: currentAffiliate.sportsbooks,
                        };
                    },
                }}>
                {this.props.children}
            </WidgetBuilderAffiliateContext.Provider>
        );
    }
}

export default WidgetBuilderAffiliateProvider;
