import React, { useContext } from 'react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Select from '@material-ui/core/Select';
import CircularProgress from '@material-ui/core/CircularProgress';
import MenuItem from '@material-ui/core/MenuItem';
import { ODDS_FORMAT, ODDS_TYPE, WidgetBuilderContext, WIDGET_SIZES, WIDGET_TYPE } from './WidgetBuilderProvider';
import { Fade } from '@material-ui/core';

export default function WidgetSettings() {
    const { widgetSize, widgetType, loading, oddsFormat, oddsType, updateOddsType, updateOddsFormat, update } =
        useContext(WidgetBuilderContext);

    const handleChangeOddsFormat = (e) => updateOddsFormat(e.target.dataset.value);

    const handleChangeOddsType = (e) => updateOddsType(e.target.dataset.value);

    const handleChangeWidgetSize = (e) => {
        const payload = e.target.value.split('x');
        const width = Number(payload[0]);
        const height = Number(payload[1]);
        update('widgetSize', `${width}x${height}`);
    };

    const widgetSizes = { ...WIDGET_SIZES };

    if (WIDGET_TYPE.UPCOMING_MATCHUPS === widgetType || WIDGET_TYPE.SINGLE_TEAM === widgetType) {
        delete widgetSizes['300x50'];
        delete widgetSizes['320x50'];
        delete widgetSizes['728x90'];
    }

    const allowedDimensions = Object.values(widgetSizes);

    return (
        <Box>
            <Box display="flex">
                <h3 style={{ margin: '0 auto 10px 0' }}>Settings</h3>
                <Fade in={loading} timeout={500}>
                    <CircularProgress size={20} color="secondary" />
                </Fade>
            </Box>
            <Box display={'flex'} alignItems="center">
                <Box flex={1} mr={2}>
                    <Select
                        style={{ height: 36 }}
                        color="secondary"
                        variant="outlined"
                        fullWidth
                        value={widgetSize}
                        onChange={handleChangeWidgetSize}>
                        {allowedDimensions.map((dimensions) => {
                            return (
                                <MenuItem key={dimensions} value={dimensions}>
                                    {dimensions}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </Box>
                <Box mr={2}>
                    <ButtonGroup disableElevation disableFocusRipple disableRipple size="small">
                        {Object.values(ODDS_FORMAT).map((format) => {
                            return (
                                <Button
                                    key={format}
                                    onClick={handleChangeOddsFormat}
                                    data-value={format}
                                    color={oddsFormat === format ? 'secondary' : 'default'}>
                                    {format}
                                </Button>
                            );
                        })}
                    </ButtonGroup>
                </Box>
                <Box>
                    <ButtonGroup disableElevation disableFocusRipple disableRipple size="small">
                        {Object.values(ODDS_TYPE).map((type) => {
                            return (
                                <Button
                                    key={type}
                                    onClick={handleChangeOddsType}
                                    data-value={type}
                                    color={oddsType === type ? 'secondary' : 'default'}>
                                    {type}
                                </Button>
                            );
                        })}
                    </ButtonGroup>
                </Box>
            </Box>
        </Box>
    );
}
