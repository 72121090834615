import React from 'react';
import { Box, Button, FormControl, InputLabel, Chip, MenuItem, Select, Input } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';

export default function BookSelect({ selectedBooks, onChange, options, onReset }) {
    return (
        <Box flex={1} display="flex">
            <Box flex={1}>
                <FormControl fullWidth variant="outlined" size="small">
                    <InputLabel id="demo-simple-select-outlined-label">Sportsbooks</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={selectedBooks}
                        multiple
                        onChange={(e) => onChange(e.target.value)}
                        label="Sportsbooks"
                        renderValue={(selected) => (
                            <div>
                                {selected.map((value) => (
                                    <Chip style={{ marginRight: 4 }} key={value} label={value} />
                                ))}
                            </div>
                        )}>
                        {options.map((option) => (
                            <MenuItem key={option} value={option}>
                                {option}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Box>

            <Box ml={1} size="small">
                <Button style={{ height: '100%' }} onClick={onReset} variant="outlined">
                    <ClearIcon />
                </Button>
            </Box>
        </Box>
    );
}
