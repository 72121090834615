import * as React from 'react';
import { Breadcrumb, BreadcrumbItem } from '@react-admin/ra-navigation';
import { PRODUCT_NAME } from '../../lib/constants';

export const AffiliateBreadcrumbs = (props) => (
    <Breadcrumb {...props}>
        <BreadcrumbItem name="dashboard" label={PRODUCT_NAME} />
        <BreadcrumbItem name="affiliates" label="Affiliates" to={'/affiliates'}>
            <BreadcrumbItem name="edit" label={({ record }) => record && `Editing "${record.company_name}"`} />
            <BreadcrumbItem name="show" label={({ record }) => record && record.company_name} />
            <BreadcrumbItem name="create" label="Create New Affiliate" />
        </BreadcrumbItem>
    </Breadcrumb>
);
