import React, { useContext, useEffect, useState } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import { WidgetBuilderContext } from './WidgetBuilderProvider';
import WidgetBuilderContent from './WidgetBuilderContent';
import WidgetLoaderSkeleton from './WidgetLoaderSkeleton';

const League = ({ season }) => {
    return (
        <Box p={0.5} display="flex" flexDirection="column">
            <h3 style={{ margin: 0, lineHeight: 1 }}>{season.league}</h3>
            <small>({season.season})</small>
        </Box>
    );
};

export default function LeagueSelect() {
    const { selectedLeagueIndex, handleChangeLeague, leagues } = useContext(WidgetBuilderContext);
    const handleChange = (_, newValue) => handleChangeLeague(newValue);

    if (leagues.length === 0) {
        return <WidgetLoaderSkeleton />;
    }
    return (
        <div>
            <AppBar style={{ borderBottom: '6px solid #efefef' }} elevation={0} color="transparent" position="static">
                <Tabs variant="fullWidth" value={selectedLeagueIndex} onChange={handleChange}>
                    {leagues.map((season) => {
                        const { _id, league, oddsAvailable } = season;
                        return <Tab disabled={false} key={_id} label={<League season={season} />} />;
                    })}
                </Tabs>
            </AppBar>
            {leagues.map((season, index) => {
                const { _id } = season;
                if (index === selectedLeagueIndex) {
                    return (
                        <Box key={_id} mt={2}>
                            <div style={{ minHeight: '75vh' }}>
                                <WidgetBuilderContent />
                            </div>
                        </Box>
                    );
                }
                return null;
            })}
        </div>
    );
}
