import React, { useEffect, useState } from 'react';
import { EditBase, useDataProvider, useEditController, useNotify, useRedirect } from 'react-admin';
import { Box, Paper, TextField, Button } from '@material-ui/core';
import { BlockPicker } from 'react-color';
import AffiliateBar from './AffiliateBar';
import { AffiliateBreadcrumbs } from './AffiliateBreadcrumbs';
import BookSelect from './BookSelect';
import WidgetLoaderSkeleton from '../WidgetBuilder/WidgetLoaderSkeleton';
import CircularProgress from '@material-ui/core/CircularProgress';
import { GeoInputs } from './GeoInputs';
import GeoTable from './GeoTable';
import GeoOptionsProvider from './GeoOptionsProvider';
import { PROD_DOMAIN } from '../../lib/endpoints';

const AffiliateEdit = (props) => {
    document.title = 'DSA - Edit Affiliate';
    const { record } = useEditController(props);
    const dataProvider = useDataProvider();
    const notify = useNotify();

    // UI State
    const [flight, setFlight] = useState(null);
    const [bookOptions, setBookOptions] = useState(null);

    useEffect(() => {
        dataProvider
            .getBookOptionsFromFeeds()
            .then(({ data }) => {
                setBookOptions(data);
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    useEffect(async () => {
        if (record) {
            const res = await dataProvider.kevelGet(`flight/${record.kevel_flight_id}`);
            if (res.data.Id) {
                setFlight(res.data);
            } else {
                notify('There was an error retrieving the flight from Kevel');
            }
        }
    }, [record]);

    if (record && bookOptions && flight) {
        return (
            <EditBase title="Edit Affiliate" {...props}>
                <Box pt={2}>
                    <AffiliateBreadcrumbs />
                </Box>
                <Box mt={2}>
                    <AffiliateBar affiliate={record} />
                </Box>
                <Paper>
                    <Box p={2}>
                        <EditAffiliateForm flight={flight} record={record} bookOptions={bookOptions} />
                    </Box>
                </Paper>
            </EditBase>
        );
    }
    return (
        <Box mt={2}>
            <WidgetLoaderSkeleton />
        </Box>
    );
};

const variant = 'outlined';

const EditAffiliateForm = ({ record, bookOptions, flight }) => {
    const notify = useNotify();
    const redirect = useRedirect();
    const dataProvider = useDataProvider();

    // UI State
    const [confirmDelete, setConfirmDelete] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showColorPicker, setShowColorPicker] = useState(false);

    // Form State
    const [companyName, setCompanyName] = useState(record.company_name);
    const [companyLogo] = useState(record.company_logo);
    const [companyColor, setCompanyColor] = useState(record.company_color);
    const [contactName, setContactName] = useState(record.contact_name);
    const [contactEmail, setContactEmail] = useState(record.contact_email);
    const [websiteURL, setWebsiteURL] = useState(record.website_url);
    const [sportsbooks, setSportsbooks] = useState(record.sportsbooks);
    const [geos, setGeos] = useState(flight.GeoTargeting);

    const handleClickSave = async (e) => {
        e.preventDefault();
        setLoading(true);

        // Update DB
        const dbPayload = {
            company_name: companyName,
            company_color: companyColor,
            contact_name: contactName,
            contact_email: contactEmail,
            company_logo: companyLogo,
            website_url: websiteURL,
            sportsbooks,
        };
        await dataProvider.update('affiliates', { data: dbPayload, id: record.id });

        // Update Site name and website url in Kevel
        await dataProvider.kevelUpdate(`site/${record.kevel_site_id}`, {
            Id: record.kevel_site_id,
            Title: companyName + '-widgets',
            Url: websiteURL,
        });

        // Update the Flight name in kevel
        const flight = await dataProvider.kevelGet(`flight/${record.kevel_flight_id}`);
        await dataProvider.kevelUpdate(`flight/${record.kevel_flight_id}`, {
            ...flight.data,
            Name: companyName + '-widgets',
        });

        notify('Affiliate updated');
        setLoading(false);
    };

    const handleConfirmDelete = async (e) => {
        e.preventDefault();
        setLoading(true);

        // Delete Site from Kevel
        await dataProvider.kevelUpdate(`site/${record.kevel_site_id}`, {
            IsDeleted: true,
            Id: record.kevel_site_id,
            Title: record.company_name + '-widgets',
            Url: record.website_url,
        });

        // Delete Flight from Kevel
        const flight = await dataProvider.kevelGet(`flight/${record.kevel_flight_id}`);
        if (flight.data) {
            await dataProvider.kevelUpdate(`flight/${record.kevel_flight_id}`, {
                ...flight.data,
                IsDeleted: true,
            });
        }

        // Delete Affiliate from DB
        await dataProvider.delete('affiliates', { id: record.id });

        notify('Affiliate deleted');
        setTimeout(() => {
            redirect('/affiliates');
        }, 1500);
    };

    const handleClickDelete = async (e) => {
        e.preventDefault();
        setConfirmDelete(true);
    };

    const disabled = !companyName || sportsbooks.length === 0 || !companyColor || !companyLogo || loading;

    const handleChangeColor = (color) => setCompanyColor(color.hex);

    const resetSelectedBooks = () => setSportsbooks([]);

    // Add Geo to kevel flight
    // Independent from the overall save button since each new geo requires a separate request.
    const handleOnAddGeo = async (geo) => {
        const payload = {
            ...geo,
            FlightId: record.kevel_flight_id,
            Region: geo.Region === 'all' ? null : geo.Region,
        };
        const res = await dataProvider.kevelCreate(`flight/${flight.Id}/geotargeting`, payload);
        if (res.data.LocationId) {
            notify('Geo Added');
            setGeos([...geos, res.data]);
        }
        if (res.data.message) {
            notify(res.data.message);
        }
    };

    const removeGeo = async (geo) => {
        // Remove from state
        const copy = [...geos];
        const filtered = copy.filter((g) => g.LocationId !== geo.LocationId);
        setGeos(filtered);

        // Remove from Kevel
        const res = await dataProvider.kevelGet(
            `flight/${record.kevel_flight_id}/geotargeting/${geo.LocationId}/delete`
        );
        notify(res.data);
    };

    const uploadAffiliateLogoToS3 = async (key, file) => {
        // Generate temporary presigned POST url to the s3 bucket.
        const res = await dataProvider.gets3PresignedPostURL(key);
        if (res.data) {
            const { fields, url } = res.data;
            const payload = {
                bucket: 'xlm-dsa',
                ...fields,
                'Content-Type': file.type,
                file,
            };
            const formData = new FormData();
            for (const name in payload) {
                formData.append(name, payload[name]);
            }
            return await fetch(url, {
                method: 'POST',
                body: formData,
            });
        }
    };

    const onLogoFileChange = async (e) => {
        var file = e.target.files[0];
        const { id } = record;

        const key = `${id}/${file.name}`;
        const res = await uploadAffiliateLogoToS3(key, file);

        // Update new affiliate logo with uploaded image saved in s3 bucket
        if (res && res.status === 204) {
            await dataProvider.update('affiliates', {
                data: {
                    company_logo: `${PROD_DOMAIN}/affiliate-logos/${key}`,
                },
                id,
            });
            notify('Logo updated');
        } else {
            notify('There was an issue uploading a new logo');
        }
    };

    return (
        <form>
            <Box mb={4}>
                <h4 style={{ marginTop: 0 }}>Website Info</h4>
                <Box display="flex" width={1000} mb={4}>
                    <Box mr={2}>
                        <TextField
                            style={{ width: 375 }}
                            size="small"
                            required
                            variant={variant}
                            color={'secondary'}
                            onChange={(e) => setCompanyName(e.target.value)}
                            label="Company Name"
                            value={companyName}
                        />
                    </Box>
                    <Box flex={2}>
                        <TextField
                            fullWidth
                            size="small"
                            required
                            variant={variant}
                            color={'secondary'}
                            onChange={(e) => setWebsiteURL(e.target.value)}
                            label="Website URL"
                            placeholder="https://"
                            value={websiteURL}
                        />
                    </Box>
                </Box>

                <Box display="flex" width={1000}>
                    <Box mr={2} width={240} style={{ position: 'relative' }}>
                        <TextField
                            size="small"
                            required
                            variant={variant}
                            color={'secondary'}
                            onFocus={() => setShowColorPicker(true)}
                            label="Company Color"
                            value={companyColor}
                        />
                        {companyColor && (
                            <div
                                style={{
                                    position: 'absolute',
                                    top: '50%',
                                    transform: 'translateY(-50%)',
                                    right: 15,
                                    width: 20,
                                    height: 20,
                                    borderRadius: 2,
                                    backgroundColor: companyColor,
                                }}></div>
                        )}
                        {showColorPicker && (
                            <div style={{ position: 'absolute', zIndex: 2 }}>
                                <div
                                    style={{ position: 'fixed', top: 0, left: 0, bottom: 0, right: 0 }}
                                    onClick={() => setShowColorPicker(false)}
                                />
                                <BlockPicker color={companyColor} onChange={handleChangeColor} />
                            </div>
                        )}
                    </Box>

                    <Box flex={2} display={'flex'}>
                        <Button variant={variant} component="label">
                            Upload New Logo
                            <input hidden type="file" onChange={onLogoFileChange} />
                        </Button>
                    </Box>
                </Box>
            </Box>
            <Box width={1000} display="flex" mb={4} alignItems="stretch">
                <BookSelect
                    onReset={resetSelectedBooks}
                    selectedBooks={sportsbooks}
                    options={bookOptions}
                    onChange={(book) => setSportsbooks(book)}
                />
            </Box>

            <Box mb={4} width={1000}>
                <h4>Contact Info</h4>
                <Box display={'flex'}>
                    <Box mr={2} flex={1}>
                        <TextField
                            fullWidth
                            size="small"
                            required
                            variant={variant}
                            color={'secondary'}
                            onChange={(e) => setContactName(e.target.value)}
                            label="Contact Name"
                            value={contactName}
                        />
                    </Box>
                    <Box flex={1}>
                        <TextField
                            fullWidth
                            size="small"
                            variant={variant}
                            color={'secondary'}
                            type="email"
                            onChange={(e) => setContactEmail(e.target.value)}
                            label="Contact Email"
                            value={contactEmail}
                        />
                    </Box>
                </Box>
            </Box>

            <GeoOptionsProvider>
                <Box width={1000}>
                    <h4>Geo Restrictions</h4>
                    <GeoInputs onAdd={handleOnAddGeo} geos={geos} />
                    <br />
                    {geos.length > 0 && <GeoTable geos={geos} handleRemoveGeo={(geo) => removeGeo(geo)} />}
                </Box>
            </GeoOptionsProvider>

            <Box mt={5} display="flex" alignItems="flex-end">
                <Button
                    style={{ marginRight: 10 }}
                    variant="outlined"
                    onClick={() => {
                        redirect('show', '/affiliates', record.id);
                    }}>
                    Done
                </Button>
                <Button
                    disabled={disabled}
                    color="secondary"
                    variant="contained"
                    type="submit"
                    onClick={handleClickSave}>
                    {loading ? <CircularProgress size={24} /> : 'Save'}
                </Button>

                <Box ml={'auto'}>
                    {confirmDelete ? (
                        <Button
                            size="small"
                            color="secondary"
                            variant="outlined"
                            type="submit"
                            onClick={handleConfirmDelete}>
                            {loading ? <CircularProgress color="secondary" size={24} /> : 'Are you sure?'}
                        </Button>
                    ) : (
                        <Button
                            size="small"
                            color="secondary"
                            variant="outlined"
                            type="submit"
                            onClick={handleClickDelete}>
                            Delete
                        </Button>
                    )}
                </Box>
            </Box>
        </form>
    );
};

export default AffiliateEdit;
