import * as React from 'react';
import { CreateButton, ListBase, useListController, useRedirect } from 'react-admin';
import { Box, Card, Paper, CardActionArea, Typography } from '@material-ui/core';
import { AffiliateBreadcrumbs } from './AffiliateBreadcrumbs';
import { makeStyles } from '@material-ui/core/styles';
import WidgetLoaderSkeleton from '../WidgetBuilder/WidgetLoaderSkeleton';
import { hexIsTooLight } from '../../lib/utilities';
import { WidgetBuilderAffiliateContext } from '../WidgetBuilder/WidgetBuilderAffiliateProvider';
import WidgetsIcon from '@material-ui/icons/Widgets';

const useStyles = makeStyles(() => ({
    logo: {
        width: 90,
        height: 30,
        marginRight: 15,
    },
    light: {
        color: '#333',
    },
    dark: {
        color: '#fff',
    },
    icon: {
        width: 14,
        cursor: 'pointer',
    },
}));

const AffiliateActions = () => {
    return (
        <Box mt={2}>
            <Paper>
                <Box p={2} mb={2} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                    <h1 style={{ margin: 0 }}>All Affiliates</h1>
                    <CreateButton color={'secondary'} variant={'text'} label="New Affiliate" />
                </Box>
            </Paper>
        </Box>
    );
};

export const AffiliateList = (props) => {
    document.title = 'DSA - Manage Affiliates';
    const redirect = useRedirect();

    const classes = useStyles();

    const controller = useListController(props);
    const { setAffiliate } = React.useContext(WidgetBuilderAffiliateContext);

    if (Object.keys(controller.data).length === 0) {
        return (
            <Box mt={2}>
                <WidgetLoaderSkeleton />
            </Box>
        );
    }

    const handleClickAffiliate = (id) => redirect('show', '/affiliates', id);

    const handleShowInBuilder = (affiliate) => {
        setAffiliate(affiliate);
        redirect('/widgets');
    };

    return (
        <ListBase {...props}>
            <Box pt={2}>
                <AffiliateBreadcrumbs />
            </Box>
            <AffiliateActions />
            <Box display={'flex'} flexWrap="wrap">
                {Object.values(controller.data).map((affiliate) => {
                    const { company_logo, company_color, company_name } = affiliate;
                    const companyColorIsLight = hexIsTooLight(company_color);

                    return (
                        <Box key={affiliate.id} mr={2} mb={2}>
                            <Card style={{ position: 'relative' }}>
                                <CardActionArea onClick={() => handleClickAffiliate(affiliate.id)}>
                                    <Box
                                        display="flex"
                                        alignItems="center"
                                        whiteSpace="nowrap"
                                        p={4}
                                        style={{ backgroundColor: company_color }}>
                                        <img width={90} height={30} src={company_logo} className={classes.logo} />
                                        <Typography
                                            className={hexIsTooLight(company_color) ? classes.light : classes.dark}>
                                            {company_name}
                                        </Typography>
                                    </Box>
                                </CardActionArea>
                                <Box position="absolute" top={5} right={10}>
                                    <WidgetsIcon
                                        onClick={() => handleShowInBuilder(affiliate)}
                                        style={{
                                            color: companyColorIsLight ? '#000' : '#fff',
                                        }}
                                        className={classes.icon}
                                    />
                                </Box>
                            </Card>
                        </Box>
                    );
                })}
            </Box>
        </ListBase>
    );
};
