import Auth from '@aws-amplify/auth';

const authGroups = ['admin', 'user'];

export default {
    login: async ({ email, password }) => Auth.signIn(email, password),

    logout: () => Auth.signOut(),

    checkError: ({ status }) => {
        if (status === 401 || status === 403) {
            return Promise.reject();
        }
        return Promise.resolve();
    },

    // called when the user navigates to a new location, to check for authentication
    checkAuth: async () => {
        const session = await Auth.currentSession();

        const userGroups = session.getAccessToken().decodePayload()['cognito:groups'];

        if (!userGroups) {
            return Promise.reject({ redirectTo: '/login' });
            // throw new Error('Unauthorized');
        }

        let notInGroup = false;

        for (const group of userGroups) {
            if (authGroups.includes(group)) {
                return;
            } else {
                notInGroup = true;
            }
        }
        if (notInGroup) {
            return Promise.reject({ redirectTo: '/login' });
        }

        throw new Error('Unauthorized'); // User is probably not assigned to a group!
    },

    // called when the user navigates to a new location, to check for permissions / roles
    getPermissions: async () => {
        try {
            const session = await Auth.currentSession();
            if (session) {
                const groups = session.getAccessToken().decodePayload()['cognito:groups'];
                return Promise.resolve(groups);
            }
        } catch (e) {
            return Promise.resolve([]);
        }
    },

    getIdentity: async () => {
        try {
            const user = await Auth.currentAuthenticatedUser();
            return Promise.resolve({ email: user.attributes.email, avatar: '', id: user.username });
        } catch (error) {
            return Promise.reject(error);
        }
    },
};
