import React, { useContext } from 'react';
import { Button, TableCell, TableBody, TableRow, TableContainer, TableHead, Table } from '@material-ui/core';
import IconDelete from '@material-ui/icons/Close';
import { GeoOptionsContext } from './GeoOptionsProvider';

export default function GeoTable({ geos = [], handleRemoveGeo, readOnly = false }) {
    const { countryOptions } = useContext(GeoOptionsContext);

    if (countryOptions) {
        return (
            <TableContainer>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Type</TableCell>
                            <TableCell>Country</TableCell>
                            <TableCell>Region</TableCell>
                            {!readOnly && <TableCell></TableCell>}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {geos.map((geo) => {
                            const country = countryOptions.find((x) => x.Code === geo.CountryCode);
                            const region = Object.values(country.Regions).find((x) => x.Code === geo.Region);
                            return (
                                <TableRow key={JSON.stringify(geo.CountryCode + geo.Region)}>
                                    <TableCell>{geo.IsExclude ? 'Exclude' : 'Include'}</TableCell>
                                    <TableCell>{`(${geo.CountryCode}) ${country ? country.Name : ''}`}</TableCell>
                                    <TableCell>{`${
                                        geo.Region === 'all' || geo.Region === null ? 'All' : `(${geo.Region})`
                                    } ${region ? region.Name : ''}`}</TableCell>
                                    {!readOnly && (
                                        <TableCell>
                                            <Button onClick={() => handleRemoveGeo(geo)}>
                                                <IconDelete />
                                            </Button>
                                        </TableCell>
                                    )}
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        );
    }
    return null;
}
