import React from 'react';
import Card from '@material-ui/core/Card';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';

export default function WidgetLoaderSkeleton({ height }) {
    return (
        <Card>
            <Box
                p={2}
                flexDirection="column"
                display="flex"
                alignItems="center"
                justifyContent="center"
                style={{ height: height ? height : '75vh' }}>
                <CircularProgress />
                <p>Loading</p>
            </Box>
        </Card>
    );
}
