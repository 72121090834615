import { RouteWithoutLayout } from 'ra-core';
import * as React from 'react';
import { Route } from 'react-router-dom';
import Profile from './pages/Profile';
import PasswordReset from './pages/PasswordReset';
import ConfirmNewUser from './pages/ConfirmNewUser';
import Dashboard from './pages/Dashboard';

export default [
    <Route key="widget-builder" exact path="/widgets" component={Dashboard} />,
    <Route key="user-profile" exact path="/profile" component={Profile} />,
    <RouteWithoutLayout key="forgot-pass" exact path="/password-reset" component={PasswordReset} />,
    <RouteWithoutLayout key="confirm-new-user" exact path="/confirm-user" component={ConfirmNewUser} />,
];
